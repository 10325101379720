import { useCallback, useEffect, useState } from "react";
import { Documents } from "src/interfaces/Documents";
import { ResponseKind } from "src/services/services/MainService";
import { documentService } from "src/services/services/documents/documents.api";
import { useAuth } from "./useAuth";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { handleUserUpdate, updateAzureToken } from "src/redux/redux-store";
import { DocumentContainerType } from "src/enums/Documents";
import { NotificationTypes } from "src/enums/Notifications";
import { sendFailureNotification } from "src/utils/notifications";

interface UseDocumentsProps {
  container?: DocumentContainerType;
}

export const useDocuments = (args: UseDocumentsProps) => {
  const dispatch = useAppDispatch();
  const container = DocumentContainerType.document;

  const azure_token = useAppSelector((state) => state.user.azureStorageToken);
  const email = useAppSelector((state) => state.user.email);
  const { token } = useAuth();

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [uploadFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadQueue, setUploadQueue] = useState<Documents[]>([]);
  const [files, setFiles] = useState<Documents[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<Documents[]>([]);
  const [failFiles, setFailFiles] = useState<Documents[]>([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (token) {
      getAzureToken();
      getDocs();
    }
  }, [token]);

  useEffect(() => {
    if (!email) {
      dispatch(handleUserUpdate());
    }
    if (uploadFiles?.length > 0 && azure_token && email) {
      uploadDoc(azure_token);
    }
  }, [uploadFiles, azure_token, email]);

  useEffect(() => {
    if (failFiles?.length > 0) {
      for (let i = failFiles.length - 1; i >= 0; i--) {
        let item = failFiles[i];
        sendFailureNotification(NotificationTypes.fileUpload, `${item.filename} was not uploaded`);
      }
    }
  }, [failFiles]);

  const transformFilesToQueueFiles = (files: File[]) => {
    const temp: Documents[] = files?.map((item, index) => {
      return {
        filename: item.name,
        content_type: item.type,
        status: "Uploading",
        id: index,
        error: "",
      };
    });

    return temp;
  };

  const getAzureToken = useCallback(async () => {
    const response = await documentService.getUserToken();

    if (response.kind == ResponseKind.OK && response.data) {
      dispatch(updateAzureToken(response.data?.azure_storage_token));
    }
  }, []);

  const clearQueue = useCallback(() => {
    setUploadQueue((prev) => []);
    getDocs();
  }, []);

  const dismissError = useCallback(() => {
    setError("");
  }, []);

  const uploadDoc = async (azure_token: string) => {
    const validationData = [...uploadFiles];
    const fileNames = validationData.map((item) => item.name);
    let filesToUpload: File[] = [];

    try {
      const validate_file_names = await documentService.validateFiles(fileNames);

      if (uploadFiles?.length > 20) {
        setError("You can upload max 20 files at one time");
        return;
      }

      if (validate_file_names.kind == ResponseKind.OK) {
        if (validate_file_names.data) {
          validate_file_names.data.files.forEach((item) => {
            if (item.exists) {
              sendFailureNotification(NotificationTypes.fileUpload, `File ${item.filename} already exists`);
              return;
            } else {
              let fileIndex = uploadFiles.findIndex((i) => i.name == item.filename);
              filesToUpload.push(uploadFiles[fileIndex]);
            }
          });
          
          const queue = transformFilesToQueueFiles(filesToUpload);
          setUploadQueue(queue);
          // processQueue(queue)
          if(filesToUpload?.length > 0){
              const failed = await documentService.uploadDoc(
              filesToUpload,
              email,
              azure_token,
              container
            );
            console.log("Files that didn't upload", failed);
            if(failed && failed?.length > 0){
              setError("A network issue was encountered! Some files were not uploaded")
            }}
        }
      } else {
        throw "File names could not be validated";
      }
    } catch (error) {
      setError(`${error}`);
    } finally {
      getDocs();
      setUploadedFiles([]);
    }
  };

  const uploadDriveFile = async (link: string): Promise<number> => {
    setLoading(true);
    const response = await documentService.uploadGdrive(link);

    try {
      if (response.kind == ResponseKind.OK) {
        if (response && response?.data) {
          if (
            response.data.queued_files &&
            response.data.queued_files?.length > 0
          ) {
            setUploadQueue(response.data.queued_files);
          }
          if (
            response.data?.failed_files?.length > 0 &&
            response.data?.queued_files?.length == 0
          ) {
            setFailFiles(response.data.failed_files);

            throw "Error";
          }
        }
      } else {
        throw "Error";
      }
    } catch (e) {
      return 1;
    } finally {
      setLoading(false);
      getDocs();
    }
    return 0;
  };

  const getDocs = async () => {
    try {
      setLoading(true);
      const response = await documentService.getDocuments();
      if (response.kind == ResponseKind.OK) {
        if (response?.data) {
          console.log("Files from doc api", response?.data);
          const queueFiles = response?.data?.processing_files ?? []
          const failedFiles = response?.data?.failed_files

          setFailFiles(failedFiles)
          setUploadQueue(queueFiles)
          setFiles(response.data.uploaded_files);
        }
      } else {
        throw "Error";
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = async (name: string) => {
    const response = await documentService.downloadDoc(name);
    if (response.kind == ResponseKind.OK) {
      if (response.data) {
        fetch(response?.data?.download_link).then((response) => {
          response.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);

            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = name || "fileName";
            alink.click();
          });
        });
      }
    }
  };

  const deleteFiles = async () => {
    if (selectedFiles?.length > 0) {
      const fileNamesToDelete = selectedFiles.map((item) => item.filename);
      const deletedFiles = files.reduce((acc: Documents[], file) => {
        if (fileNamesToDelete.includes(file.filename)) {
          // Push a new object with updated status to the accumulator
          acc.push({ ...file, status: "Deleting" });
        }
        return acc;
      }, []);
      setUploadQueue(deletedFiles);
      const response = await documentService.deleteDocs(fileNamesToDelete);

      if (response.kind == ResponseKind.OK) {
        processDeletion(fileNamesToDelete);
      } else {
        // Optionally handle API errors
        console.error("Failed to delete files:");
      }
    }
  };

  const processDeletion = (fileNames: string[]) => {
    const updatedFiles = files.filter((file) => !fileNames.includes(file.filename));
    setFiles(updatedFiles);
  };

  const deleteSingleFile = async (fileName: string) => {
    if (fileName) {
      const response = await documentService.deleteDocs([fileName]);
      if (response.kind == ResponseKind.OK) {
        getDocs();
      }
    }
  };

  return {
    deleteFiles,
    downloadFile,
    error,
    uploadDriveFile,
    uploadDoc,
    loading,
    clearQueue,
    failFiles,
    uploadQueue,
    getDocs,
    setSelectedFiles,
    selectedFiles,
    dismissError,
    deleteSingleFile,
    files,
    uploadFiles,
    setUploadedFiles,
  };
};
