import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { COLORS } from "src/utils/styles";

const useResponsive = (query?: string) => {
    const isMobile = useMediaQuery(query ? query  : "(max-width: 992px)");
    const isMobileMediumAndSmall = useMediaQuery(query ? query  : "(max-width: 425px)");
    const isMobileLarge = useMediaQuery(query ? query  : "(min-width: 425px) and (max-width: 768px)");
    const isTablet = useMediaQuery(query ? query  : "(min-width: 768px) and (max-width: 1024px)");
    const isDesktop = useMediaQuery(query ? query  : "(min-width: 1024px)");
    const theme = useMantineTheme();
    const backgroundColor =
    theme.colorScheme == "dark" ? 'black' : theme.white;
  const backgroundColorExterior =
    theme.colorScheme == "dark" ? theme.colors.dark[7] : COLORS.bg;

    return {
        isMobile,
        backgroundColor,
        backgroundColorExterior,
        isMobileLarge,
        isMobileMediumAndSmall,
        isTablet,
        isDesktop
    }
}

export default useResponsive
